/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAdminData } from 'Contexts/AdminContext';
import {
  getReferralsFromSchoolId,
  getReferralFromSchoolIdAndReferralId,
  updateReferralBySchoolIdAndReferralId,
  finishReferralBySchoolIdAndReferralId,
  reactivateReferralBySchoolIdAndReferralId,
} from '../../../../dist/Collections/referrals';
import fields from '../fields';

import {
  costValues,
  typeValues,
  yearsValues,
} from '../optionValues';

import {
  doReferralDetailsInsert,
  doReferralsInsert,
  doReferralDetailsDelete,
  doReferralFilterSet,
  doAcceptReferralConditions,
} from '../../../../redux/referrals';

const NOT_ACCEPT_CONDITIONS = 0;

const useReferrals = () => {
  const dispatch = useDispatch();
  const { referralsList, referralDetails, filters } = useSelector((state) => state.referrals);
  const [acceptReferralDialog, setAcceptReferralDialog] = useState(null);
  const [isLoading, setLoading] = useState({ list: false, details: false });

  const {
    schoolId: selectedSchoolId,
  } = useAdminData();

  const localData = localStorage.getItem('vacanted');
  const dataUser = JSON.parse(localData);
  const schoolIdFromUser = dataUser.user.school_id;

  const setLoadingList = (value) => {
    setLoading((prevState) => ({ ...prevState, list: value }));
  };

  const setLoadingDetails = (value) => {
    setLoading((prevState) => ({ ...prevState, details: value }));
  };

  const optionValues = {
    yearsValues: Object.keys(yearsValues).map((key) => ({ value: key, label: yearsValues[key] })),
    typeValues: Object.keys(typeValues).map((key) => ({ value: key, label: typeValues[key] })),
    costValues: Object.keys(costValues).map((key) => ({ value: key, label: costValues[key] })),
  };

  const vacantestMapper = (vacantest) => {
    const {
      id,
      test_date: testDate,
      email,
      start_year: startYear,
      educ_levels: educLevels,
      languages,
      costs: { highschoolCost, elementarySchoolCost },
      result,
      state_id: stateId,
    } = vacantest;

    return {
      id,
      testDate,
      email,
      startYear,
      educLevels,
      languages,
      elementarySchoolCost,
      highschoolCost,
      stateId,
      totalAffinity: result.affinity.totalAffinity,
      position: result.position,
      distanceKms: result.distanceKms,
    };
  };

  const referralRowMapper = (referral) => {
    const {
      id,
      user,
      created_at: createdAt,
      state_id: stateId,
      alerts,
      conditions_accepted_by_school: conditionsAcceptedBySchool,
    } = referral;

    return {
      referral: {
        id,
        name: user.email,
        createdAt,
      },
      stateId,
      alert: alerts?.length > 0 ? alerts[0].id : null,
      conditionsAcceptedBySchool,
    };
  };

  const onUpdateReferral = async (referralId, payload) => {
    setLoadingDetails(true);
    try {
      const data = payload;
      const response = await updateReferralBySchoolIdAndReferralId(
        schoolIdFromUser, referralId, data,
      );
      const updateSuccess = response.message === 'Update performed successfully';
      if (updateSuccess) {
        onSearchReferrals();
        onSearchReferral(referralId);
      }
      return updateSuccess;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    } finally {
      setLoadingDetails(false);
    }
  };

  const updateAcceptReferralConditions = async (referralId, accept) => {
    try {
      const payload = {
        conditions_accepted_by_school: accept,
      };

      const response = await onUpdateReferral(referralId, payload);
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    }
  };

  const insertComment = async (referralId, newComment) => {
    try {
      const payload = {
        new_comment: newComment,
      };
      const response = await onUpdateReferral(referralId, payload);
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    }
  };

  const finishReferral = async (referralId, finalStateId) => {
    setLoadingDetails(true);
    try {
      await finishReferralBySchoolIdAndReferralId(
        schoolIdFromUser, referralId, finalStateId,
      );
      onSearchReferrals();
      onSearchReferral(referralId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    } finally {
      setLoadingDetails(false);
    }
  };

  const reactivateReferral = async (referralId) => {
    setLoadingDetails(true);
    try {
      await reactivateReferralBySchoolIdAndReferralId(
        schoolIdFromUser, referralId,
      );
      onSearchReferrals();
      onSearchReferral(referralId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    } finally {
      setLoadingDetails(false);
    }
  };

  const referralMapper = (referral) => {
    const {
      id,
      user,
      created_at: createdAt,
      updated_at: updatedAt,
      state_id: stateId,
      alerts,
      details,
      chat_id: chatId,
      comments,
    } = referral;

    return {
      id,
      stateId,
      name: user.email,
      createdAt,
      updatedAt,
      alert: alerts.length > 0 ? alerts[0].id : null,
      chatId,
      comments,
      details: {
        messages: details?.messages,
        tests: details?.tests?.map(vacantestMapper),
      },
      update: {
        acceptConditions: async (accept) => updateAcceptReferralConditions(id, accept),
        // state: async (newStateId) => updateReferralState(id, newStateId),
        finish: async (finalStateId) => finishReferral(id, finalStateId),
        reactivate: async () => reactivateReferral(id),
      },
      insert: {
        comment: async (newComment) => insertComment(id, newComment),
      },
      refresh: async () => onSearchReferral(id),
    };
  };

  const getSchoolId = () => schoolIdFromUser || selectedSchoolId;

  const getReferrals = async (stateIds, withAlertOnly) => {
    const referralsResponse = await getReferralsFromSchoolId(
      getSchoolId(), stateIds, withAlertOnly,
    );
    return referralsResponse?.map(referralRowMapper) || [];
  };

  const getReferralDetails = async (referralId) => {
    const referral = await getReferralFromSchoolIdAndReferralId(getSchoolId(), referralId);
    return referralMapper(referral);
  };

  const onSearchReferrals = async (newFilters) => {
    setLoadingList(true);
    try {
      const currentFilters = newFilters || filters;
      const referrals = await getReferrals(currentFilters.stateIds, currentFilters.withAlertOnly);
      dispatch(doReferralsInsert(referrals));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoadingList(false);
    }
  };

  const onSearchReferral = async (referralId) => {
    setLoadingDetails(true);
    const referralPreView = referralsList.find((item) => item.referral.id === referralId);
    try {
      if (referralPreView?.conditionsAcceptedBySchool === NOT_ACCEPT_CONDITIONS
        && schoolIdFromUser
      ) {
        setAcceptReferralDialog({
          referralId,
          name: referralPreView.referral.name,
        });
        return;
      }

      const referral = await getReferralDetails(referralId);

      dispatch(doReferralDetailsInsert(referral));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      if (error.code === 401) {
        setAcceptReferralDialog({
          referralId,
          name: referralPreView.referral.name,
        });
      }
    } finally {
      setLoadingDetails(false);
    }
  };

  const onFilterReferrals = async (payload) => {
    const stateIds = payload?.stateIds ? payload.stateIds : [];
    const withAlertOnly = payload?.withAlertOnly || false;
    const newFilters = { stateIds, withAlertOnly };
    onSearchReferrals(newFilters);
    dispatch(doReferralFilterSet(newFilters));
  };

  useEffect(() => {
    onSearchReferrals();
  }, []);

  useEffect(() => {
    if (selectedSchoolId) onSearchReferrals();
  }, [selectedSchoolId]);

  const onAcceptReferralDialog = async (accept) => {
    const { referralId } = acceptReferralDialog;
    if (accept) {
      setLoadingList(true);
      updateAcceptReferralConditions(referralId, accept)
        .then(async () => {
          dispatch(doAcceptReferralConditions(referralId));
          setAcceptReferralDialog(null);
          const referral = await getReferralDetails(referralId);
          dispatch(doReferralDetailsInsert(referral));
          onSearchReferrals();
        });
    } else {
      setAcceptReferralDialog(null);
    }
  };

  return {
    referrals: referralsList,
    referralDetails,
    fields,
    filters,
    optionValues,
    isLoading,
    acceptReferralDialog,
    onAcceptReferralDialog,
    onFilterReferrals,
    onSearchReferral,
    onReferralDetailsClose: () => dispatch(doReferralDetailsDelete()),
  };
};

export default useReferrals;
