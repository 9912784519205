import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext();

function UserContextProvider({ children }) {
  const [userData, setUserData] = useState({ loginStatus: false, data: [] });
  const [loading, setLoading] = useState(true);

  const logout = async () => {
    setUserData({ loginStatus: false, data: [] });
  };

  return (
    <UserContext.Provider value={{
      userData,
      setUserData,
      loading,
      setLoading,
      logout,
    }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UserContextProvider;

export function useUserData() {
  const context = useContext(UserContext);
  return context;
}
