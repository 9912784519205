import { formatISO9075 } from 'date-fns';

export default {
  getDateValueFromFilter: (filterState, timePeriods, customRange) => {
    let range = {
      startDate: '2021-01-01',
      endDate: formatISO9075(new Date(), { representation: 'date' }),
    };

    if (filterState === 'all_history') range = timePeriods.allHistory;
    if (filterState === 'all_year') range = timePeriods.allYear;
    if (filterState === 'last_six_months') range = timePeriods.lastSixMonths;
    if (filterState === 'last_three_months') range = timePeriods.lastThreeMonths;
    if (filterState === 'last_thirty_days') range = timePeriods.lastThirtyDays;
    if (filterState === 'custom') range = customRange;

    return range;
  },
};
