const groupBy = (data, getKey) => data.reduce((acc, curr) => {
  const key = getKey(curr);
  if (key) {
    if (acc[key]) {
      acc[key].push(curr);
    } else {
      acc[key] = [curr];
    }
  }
  return acc;
}, {});

export default groupBy;
