import { useEffect, useRef, useState } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getDataFromLocalStorage, saveDataInLocalStorage } from 'dist/Storage/localStorageMethods';
import { getFirestore } from 'firebase/firestore';
import api from 'Services/api';
import chatUtils from './utils';

const db = getFirestore();
const auth = getAuth();

const useFirebase = () => {
  // START Firebase auth stuff
  const { token } = getDataFromLocalStorage('vacanted');
  const [firebaseInfo, setFirebaseInfo] = useState();
  const firebaseInfoRef = useRef();
  const updateFirebaseInfo = () => {
    api.get('/user/firebase-token', {
      headers: {
        authorization: token,
      },
    }).then((firebaseInfoFromApi) => {
      setFirebaseInfo(firebaseInfoFromApi.data);
      saveDataInLocalStorage('firebase-info', firebaseInfoFromApi.data);
    }).catch((err) => {
      chatUtils.saveErrorInFirebase(db, null, `Failed to get firebase token for user ${token}`, err);
    });
  };

  useEffect(() => {
    const existingFirebaseInfo = getDataFromLocalStorage('firebase-info');
    if (existingFirebaseInfo) {
      setFirebaseInfo(existingFirebaseInfo);
    } else {
      updateFirebaseInfo();
    }
  }, []);

  useEffect(async () => {
    if (!firebaseInfo) return;
    firebaseInfoRef.current = firebaseInfo;

    if (!auth.currentUser || auth.currentUser.uid !== firebaseInfo.id) {
      signInWithCustomToken(auth, firebaseInfo.token)
        .then(() => {})
        .catch((error) => {
          if (error.code === 'auth/invalid-custom-token') {
            updateFirebaseInfo();
          } else {
            chatUtils.saveErrorInFirebase(db, null, `Failed to init chat view ${token}`, error);
          }
        });
    }
  }, [firebaseInfo]);
  // END Firebase auth stuff

  return {
    firebaseInfo,
    firebaseInfoRef,
  };
};

export default useFirebase;
