import React, { useEffect, useState } from 'react';
import {
  Card, Container, Form, Row, Col, Button,
} from 'react-bootstrap';
import { formatISO9075 } from 'date-fns';
import Select from 'react-select';

import { useAdminData } from 'Contexts/AdminContext';
import dateRange from 'dist/Utils/dateRanges';
import getReportByDateFilter from 'hooks/getReportByDateFilter';
import exportToExcel from './components/DownloadReport';

export default function InternalReports() {
  const [dateFilter, setDateFilter] = useState('');
  const [reportFilter, setReportFilter] = useState([]);
  const [data, setData] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    startDate: '2021-01-01',
    endDate: formatISO9075(new Date(), { representation: 'date' }),
  });

  const { country } = useAdminData();

  const reportOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'views', label: 'Visualizaciones de ficha' },
    { value: 'interviews', label: 'Solicitud de entrevistas' },
    { value: 'consultings', label: 'Envio de consulta' },
    { value: 'visit_requests', label: 'Solicitud de agenda de visita guiada' },
    { value: 'postulations', label: 'Postulaciones solicitadas' },
    { value: 'vacantest_results', label: 'Resultados en Vacantest' },
    { value: 'search_results', label: 'Resultados de búsquedas guardadas' },
  ];

  const dateOptions = [
    { value: 'all_history', label: 'Todo el historial' },
    { value: 'all_year', label: 'Todo el año' },
    { value: 'last_six_months', label: 'Últimos 6 meses' },
    { value: 'last_three_months', label: 'Últimos 3 meses' },
    { value: 'last_thirty_days', label: 'Últimos 30 días' },
    { value: 'custom', label: 'Periodo personalizado' },
  ];

  function filterData() {
    const applyFilters = data.map((item) => {
      const keys = Object.keys(item);
      let result = {};
      for (let i = 0; i <= reportFilter.length; i += 1) {
        if (reportFilter[i] === 'Todos') result = item;
        if (reportFilter[i] === 'Resultados en Vacantest' && (item['Vacantest hasta 50'] > 0 || item['Vacantest hasta 15'] > 0 || item['Vacantest hasta 5'] > 0)) {
          result['ID Vacanted'] = item['ID Vacanted'];
          result['Nombre del Centro'] = item['Nombre del Centro'];
          result['Vacantest hasta 50'] = item['Vacantest hasta 50'];
          result['Vacantest hasta 15'] = item['Vacantest hasta 15'];
          result['Vacantest hasta 5'] = item['Vacantest hasta 5'];
        }

        if (reportFilter[i] === 'Resultados de búsquedas guardadas' && (item['Búsquedas guardadas hasta 50'] > 0 || item['Búsquedas guardadas hasta 15'] > 0 || item['Búsquedas guardadas hasta 5'] > 0)) {
          result['ID Vacanted'] = item['ID Vacanted'];
          result['Nombre del Centro'] = item['Nombre del Centro'];
          result['Búsquedas guardadas hasta 50'] = item['Búsquedas guardadas hasta 50'];
          result['Búsquedas guardadas hasta 15'] = item['Búsquedas guardadas hasta 15'];
          result['Búsquedas guardadas hasta 5'] = item['Búsquedas guardadas hasta 5'];
        }

        if (keys.includes(reportFilter[i])) {
          result['ID Vacanted'] = item['ID Vacanted'];
          result['Nombre del Centro'] = item['Nombre del Centro'];
          result[reportFilter[i]] = item[reportFilter[i]];
        }
      }
      return result;
    });

    const noResults = {
      'ID Vacanted': '',
      'Nombre del Centro': '',
    };

    if (reportFilter.includes('Todos')) {
      for (let i = 0; i < reportOptions.length; i += 1) {
        if (reportOptions[i].label !== 'Todos') {
          noResults[reportOptions[i].label] = '';
        }
      }
    }

    for (let i = 0; i < reportFilter.length; i += 1) {
      noResults[reportFilter[i]] = '';
    }

    const filteredResult = applyFilters.filter((value) => {
      let isZero = 0;
      for (let i = 0; i < reportFilter.length; i += 1) {
        if (value[reportFilter[i]] === 0) isZero += 1;
      }
      return isZero !== reportFilter.length;
    });

    if (filteredResult.length === 0) {
      return [noResults];
    } return filteredResult;
  }

  function handleMultiSelectChange(e) {
    const selectValues = e.map((item) => item.label);
    return setReportFilter(selectValues);
  }

  function handleDateSelectChange(e) {
    const { value } = e;
    return setDateFilter(value);
  }

  function handleInitialDateChange(e) {
    const { value } = e.target;
    return setCustomDateRange({ ...customDateRange, startDate: value });
  }

  function handleFinalDateChange(e) {
    const { value } = e.target;
    return setCustomDateRange({ ...customDateRange, endDate: value });
  }

  function handleClick() {
    const filteredData = filterData();
    return exportToExcel(filteredData);
  }

  useEffect(async () => {
    const dataFetched = await getReportByDateFilter(
      dateFilter, country.id, dateRange, customDateRange,
    );
    return setData(dataFetched);
  }, [dateFilter, customDateRange]);

  return (
    <>
      <Container style={{ height: '100vh' }}>
        <h1 style={{ margin: '0', padding: '30px 0 15px 0' }}>Informes Globales Internos Multicentro</h1>
        <Card style={{ padding: '1rem' }}>
          <Row>
            <Col xs={2}>
              <Form.Group>
                <Form.Control type="text" value={country.name} readOnly />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Select
                  options=""
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccionar ciudad"
                  onChange=""
                  isDisabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Select
                  options=""
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccionar embajador"
                  onChange=""
                  isDisabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'flex-start', margin: '1rem 0' }}>
            <Col md="6" style={{ paddingLeft: '0' }}>
              <Form.Group>
                <Form.Label>Datos a visualizar en el reporte</Form.Label>
                <Select
                  options={reportOptions}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => handleMultiSelectChange(e)}
                  placeholder="Seleccionar opciones"
                />
              </Form.Group>
            </Col>
            <Col md="6" style={{ paddingRight: '0' }}>
              <Form.Group>
                <Form.Label>Indique periodo de tiempo</Form.Label>
                <Select
                  options={dateOptions}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccionar opción"
                  onChange={(e) => handleDateSelectChange(e)}
                />
              </Form.Group>
              {
              (dateFilter === 'custom')
                ? (
                  <>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <Form.Label>Desde</Form.Label>
                          <Form.Control type="date" onChange={(e) => handleInitialDateChange(e)} />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <Form.Label>Hasta</Form.Label>
                          <Form.Control type="date" onChange={(e) => handleFinalDateChange(e)} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )
                : ''
            }
            </Col>
          </Row>
          <Row style={{ textAlign: 'right' }}>
            <Col md="9" />
            <Col md="3" style={{ margin: '0' }}>
              <Button
                style={{ marginTop: '1rem' }}
                onClick={() => handleClick()}
              >
                EXPORTAR A EXCEL
              </Button>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}
