import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const classNames = {
  row: 'referral-row',
  id: 'referral-row__id',
  name: 'referral-row__name',
  date: 'referral-row__date',
};

const ReferralCell = ({ value }) => {
  const { id, createdAt, name } = value;
  const date = moment(createdAt)?.format('DD/MM/YYYY');

  return (
    <td className={classNames.row}>
      <span className={classNames.id}>
        #
        {id}
      </span>
      <span className={classNames.name}>{name}</span>
      <span className={classNames.date}>
        Ingresado
        {' '}
        {date}
      </span>
    </td>
  );
};

ReferralCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default ReferralCell;
