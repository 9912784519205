import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const pdfPostulation = (info) => {
  const postulated = info.report['6'].find((child) => child.postulate.status === true);
  const index = info.report['6'].findIndex((child) => child.postulate.status === true);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const mainContent = [
    {
      text: [
        {
          text: 'VACANTED™', fontSize: 35, style: 'header',
        },
      ],
      style: 'header',
      bold: true,
      color: '#36a7eb',
      alignment: 'center',
      margin: [0, 0, 0, 20],
    },

    // title and subtitles with informations
    {
      text: `NUEVA POSTULACIÓN - ${info.school.name}`,
      style: 'header',
      fontSize: 25,
      bold: true,
      alignment: 'center',
      margin: [0, 30, 0, 5],
    },
    {
      text: [
        { text: 'Identificación: ', bold: true },
        { text: `${info.user.email}`, bold: false },
      ],
      style: 'subheader',
      fontSize: 12,
      alignment: 'center',
    },

    // 1- Presentación de quien completa el informe
    {
      text: 'Integrante',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 40, 0, 10],
    },
    {
      text: index + 1,
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },

    {
      text: 'Nivel',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 40, 0, 10],
    },
    {
      text: postulated.age.name,
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      text: 'Año de ingreso',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 40, 0, 10],
    },
    {
      text: postulated.postulate.year?.name || '-',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },

    {
      canvas: [
        {
          type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1,
        },
      ],
    },

    {
      text: '¿Cuáles son las motivaciones para postular a esta institución?',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 40, 0, 10],
    },
    {
      text: info.question1,
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },

    {
      text: '¿Has tenido anteriormente algún relacionamiento o entrevista con dicha institución?',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 40, 0, 10],
    },
    {
      text: info.question2,
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },

  ];

  function footer(currentPage, pageCount) {
    return [
      {
        text: `${currentPage} de ${pageCount}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 40, 0],
      },
    ];
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 50, 40, 45],

    content: [mainContent],
    footer,
  };

  pdfMake.createPdf(docDefinition).download('postulation.pdf');
};

export default pdfPostulation;
