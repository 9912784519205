import randomstring from 'randomstring';
/**
 * @param {string} prefix  string that will be the prefix of the generated string. default is ''
 * @param {number} length  numeric value of length of the generated string. default is 6
 * @returns {string}  string that will have the prefix + random generated string
*/
const generateRandomString = (prefix = '', length = 6) => {
  const random = randomstring.generate({
    length,
    charset: 'alphabetic',
  });
  return prefix + random;
};

export default generateRandomString;
