// this creates the intial state of this component based on the collapse routes
// that it gets through routes prop

export const getCollapseInitialState = (routeList, location) => {
  for (let i = 0; i < routeList.length; i += 1) {
    if (routeList[i].collapse && getCollapseInitialState(routeList[i].views)) {
      return true;
    } if (location.pathname === routeList[i].layout + routeList[i].path) {
      return true;
    }
  }
  return false;
};

// this verifies if any of the collapses should be default opened on a rerender of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms

// this function creates the links and collapses that appear in the sidebar (left menu)

export const getCollapseStates = (routes) => {
  let initialState = {};
  routes.map((prop) => {
    if (prop.collapse) {
      initialState = {
        [prop.state]: getCollapseInitialState(prop.views),
        ...getCollapseStates(prop.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};
