import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import styles from './styles.module.css';

export default function SelfSpinner() {
  return (
    <div className={styles['container-spinner']}>
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    </div>
  );
}
