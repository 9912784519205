import api from 'Services/api';

export default async function getAllUsers() {
  const users = await api
    .get('/user/management')
    .then((res) => res.data)
    .catch((err) => err);

  return users;
}
