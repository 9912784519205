import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  getFirestore,
  // connectFirestoreEmulator,
} from 'firebase/firestore';
import {
  getStorage, ref, uploadBytes,
  // connectStorageEmulator,
} from 'firebase/storage';
import chatUtils from './utils';

const db = getFirestore();
// connectFirestoreEmulator(db, 'localhost', 5003);
const storage = getStorage();
// connectStorageEmulator(storage, 'localhost', 5004);

const SimpleFileLoader = ({
  memberId, callbackWithFile,
}) => {
  const userPreffix = `chatFiles/${memberId}`;
  const maxFileSizeInMb = 5;
  const maxFileSizeInBytes = 1024 * 1024 * maxFileSizeInMb;
  const fileInputRef = useRef();

  useEffect(async () => {
    document.querySelector('#file-selector').addEventListener('cancel', (_evt) => {
      callbackWithFile(null);
    });
    fileInputRef.current.click();
  }, []);

  const handleFileSelectedForUpload = (e) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (file.size > maxFileSizeInBytes) {
        toast.error(
          `El archivo seleccionado supera los ${maxFileSizeInMb} MB`,
          { onClose: () => callbackWithFile(null) },
        );
      } else {
        const path = `${userPreffix}/${new Date().toISOString().substring(0, 19)}_${file.name}`;
        const storageRef = ref(storage, path);
        uploadBytes(storageRef, file)
          .then((_snapshot) => {
            callbackWithFile({ name: file.name, fullPath: path });
          })
          .catch((err) => chatUtils.saveErrorInFirebase(db, memberId, `Failed to upload file ${path}`, err));
      }
    }
  };

  return (
    <>
      <input id="file-selector" className="hidden" type="file" ref={fileInputRef} onChange={handleFileSelectedForUpload} />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        limit={1}
      />
    </>
  );
};

SimpleFileLoader.propTypes = {
  memberId: PropTypes.string,
  callbackWithFile: PropTypes.func,
}.isRequired;

export default SimpleFileLoader;
