import {
  addDoc, collection,
  // connectFirestoreEmulator,
} from 'firebase/firestore';

const saveErrorInFirebase = (db, memberId, message, error) => {
  addDoc(collection(db, 'errors'), {
    message,
    error_code: error.code || '',
    error_message: error.message || '',
    member: memberId || '',
    timestamp: Date.now(),
  });
};

const isSchool = (memberOrRole) => `${memberOrRole}`.includes('school');
const isAdmin = (memberOrRole) => `${memberOrRole}`.includes('admin');
const isFamily = (memberOrRole) => `${memberOrRole}`.includes('family');

const getOtherParticipantIds = (chatParticipants, myMemberId) => Object.keys(chatParticipants)
  .filter((id) => id !== myMemberId);

const getOtherParticipant = (chatParticipants, myMemberId) => {
  const recipientId = getOtherParticipantIds(chatParticipants, myMemberId)[0];
  return chatParticipants[recipientId];
};

const getOtherParticipantNamesConcat = (chatParticipants, myMemberId) => Object.keys(chatParticipants).filter((id) => id !== myMemberId).map((id) => (isFamily(id) ? chatParticipants[id].email : chatParticipants[id].name)).join(' - ');

const getOtherParticipantLogo = (chatParticipants, myMemberId) => {
  const otherIds = getOtherParticipantIds(chatParticipants, myMemberId);
  let other = chatParticipants[otherIds[0]];
  if (isAdmin(myMemberId)) {
    const schoolMember = otherIds.find(isSchool);
    other = chatParticipants[schoolMember];
  }
  return other.logo;
};

export default {
  saveErrorInFirebase,
  getOtherParticipantIds,
  getOtherParticipant,
  getOtherParticipantNamesConcat,
  getOtherParticipantLogo,
  isSchool,
  isAdmin,
  isFamily,
};
